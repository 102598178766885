import React from "react";
import Profile from "../components/profile/profile";


function LoginPage() {
    return (
        <Profile type="setup" />
    );
}


export default LoginPage;